<template>
    <v-container>
      <v-row>
        <v-col class="mt-4">
          <p class="text-h5 white--text">จัดการผู้ใช้งานระบบ</p>
        </v-col>
        <v-col class="mt-4 text-right">
            <v-btn dark color="yellow darken-3" outlined @click="$router.push({ name: 'UsersCreate' })">
                + สร้างผู้ใช้งาน
            </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <CardReport1 :title="'ผู้ใช้งานทั้งหมด'" :number="total" :showPercent="false" :avatar="'/icon/move-vai.jpg'"/>
        <CardReport1 :title="'กำลังใช้งาน'" :number="activeNumber" :showPercent="false" :avatar="'/icon/user_green.png'"/>
        <CardReport1 :title="'ระงับการใช้งาน'" :number="suspendedNumber" :showPercent="false" :avatar="'/icon/user_green.png'"/>
      </v-row>
      <v-row>
          <v-data-table
          :loading="isLoading"
          loading-text="กำลังโหลด....... กรุณารอซักครู่"
          v-model="selected"
          :headers="headers"
          :items-per-page="30"
          :items="data"
          class="elevation-1"
          style="width: 100%; border: 0.5px solid #aaaaaa;"
          show-select
          no-data-text="ไม่พบข้อมูล"
          :search="search"
          dark
          @click:row="clickRow"
          >
            <template v-slot:top>
              <p class="text-h6 ma-2 white--text">ผู้ใช้งานระบบทั้งหมด  <span class="yellow--text text--darken-3"> {{ total }} คน </span> </p>
              <div class="d-flex pa-3">
                    <div class="mr-auto">
                        <v-text-field v-model="search" dense label="ค้นหารายการ">
                            <v-icon
                            slot="prepend"
                            >
                            mdi-magnify
                            </v-icon>
                            </v-text-field>
                    </div> 
                    <div class="px-3">
                        <v-btn plain :disabled="!downloadCSVButton" @click="downloadCSV">
                        <v-icon
                            right
                            dark
                            class="mr-2"
                        >
                            mdi-cloud-download
                        </v-icon>
                            ดาวน์โหลด CSV
                        </v-btn>
                    </div>
              </div>
            </template>
             <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.name }}</span>
              <span class="d-block grey--text">{{ item.email }}</span>
            </template>
             <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
              <span class="d-block grey--text">ใช้งานล่าสุด {{ formatDate(item.updatedAt) }}</span>
            </template>
            <template v-slot:[`item.role`]="{ item }">
              <span class="text-capitalize">
                {{ item.role }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                outlined
                class="ma-2"
                :class="['กำลังใช้งาน' == item.status ? 'green--text' : 'grey--text']"
              >
                <v-icon left :class="{ 
                  'green--text': 'กำลังใช้งาน' == item.status, 
                  'grey--text text--lighen-2': 'รอยืนยันตัวตน' == item.status,
                  'red--text': 'ระงับการใช้งาน' == item.status
                }">
                  mdi-circle-medium
                </v-icon>
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-container class="text-right">
                <v-btn 
                  color="yellow darken-3"
                  depressed
                  elevation="2"
                  class="mr-2"
                  @click.stop.prevent="$router.push({ name: 'UsersEdit', params: { id: item.userId } })"
                >
                <span class="black--text">แก้ไข</span>
                </v-btn>
                <v-btn 
                  v-if="item.statusCode !='suspended'"
                  color="yellow darken-3"
                  depressed
                  elevation="2"
                  @click.stop.prevent="$refs.dialogSuspend.show(item.userId)"
                >
                <span class="black--text">ระงับการใช้</span>
                </v-btn>
                <v-btn 
                  v-if="item.statusCode =='suspended'"
                  color="yellow darken-3"
                  depressed
                  elevation="2"
                  @click.stop.prevent="$refs.dialogUnSuspend.show(item.userId)"
                >
                <span class="black--text">ยกเลิกระงับ</span>
                </v-btn>
                
              </v-container>
            </template>
          </v-data-table>
      </v-row>
      <dialog-suspend @confirm="confirmSuspend" ref="dialogSuspend" />
      <dialog-un-suspend @confirm="confirmUnSuspend" ref="dialogUnSuspend" />
      <alert-error :text="errorMessage" ref="alertError" />
    </v-container>
</template>

<script>
import CardReport1 from '../../components/CardReport1.vue';
import { getUsers } from '../../models/user';
import DialogSuspend from '../../components/DialogSuspend.vue';
import DialogUnSuspend from '../../components/DialogUnSuspend.vue';
import AlertError from '../../components/AlertError.vue';
import moment from 'moment';
import axios from 'axios';
import fd from 'js-file-download';

export default {
      data: () => ({
    //
    search: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'รหัสพนักงาน', value: 'id', sortable: false },
      { text: 'ชื่อพนักงาน', value: 'name', sortable: false },
      { text: 'สร้างวันที่', value: 'createdAt' },
      { text: 'Role', value: 'role', sortable: false },
      { text: 'สถานะการใช้งาน', value: 'status' },
      { text: '', value: 'action', sortable: false }
    ],
    data: [],
    isLoading: false,
    errorMessage: '',
    total: 0,
    downloadCSVButton: false,
    activeNumber: 0,
    suspendedNumber: 0
  }),
  methods: {
    async confirmSuspend(){
      await this.getListUsers();
    },
    async confirmUnSuspend(){
      await this.getListUsers();
    },
    async getListUsers(){
      try{
        this.isLoading =true;
        const response = await getUsers();
        this.data = response.users;
        this.total = response.total;
        await this.getSummary();
      }catch(error){
        this.errorMessage = error.response?.data || error.message;
        this.$refs.alertError.show();
      }finally{
        this.isLoading = false;
      }
    },
    formatDate(date){
      return date ? moment(date).format('DD-MM-YY') : '';
    },
    clickRow(e){
      this.$router.push( { name: 'UsersDetails', params: { id: e.userId } });
    },
    downloadCSV(){
      axios.post(process.env.VUE_APP_BASE_URL + '/reports/user', { users: this.selected.map(u => u.userId) }, { responseType: 'blob' })
        .then(response => {
            fd(response.data, 'user.csv', response.headers['content-type']);
        });
    },
    async getSummary(){
      try{
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/users/summary');
        this.activeNumber = response.data.active;
        this.suspendedNumber = response.data.suspended;
      }catch(error){
        this.errorMessage = error.response?.data || error.message;
        this.$refs.alertError.show();
      }finally{
        this.isLoading = false;
      }
    }
  },
  async mounted(){
    await this.getListUsers();
  },
  watch: {
    selected(){
      if(0 == this.selected.length){
        this.downloadCSVButton = false;
      }else{
        this.downloadCSVButton = true;
      }
    }
  },
  components: {
    CardReport1,
    DialogSuspend,
    AlertError,
    DialogUnSuspend
  }
};
</script>